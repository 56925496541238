// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-thank-you-js": () => import("/opt/build/repo/src/pages/contact-thank-you.js" /* webpackChunkName: "component---src-pages-contact-thank-you-js" */),
  "component---src-pages-contact-us-js": () => import("/opt/build/repo/src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-evaluation-thank-you-js": () => import("/opt/build/repo/src/pages/evaluation-thank-you.js" /* webpackChunkName: "component---src-pages-evaluation-thank-you-js" */),
  "component---src-pages-free-security-evaluation-js": () => import("/opt/build/repo/src/pages/free-security-evaluation.js" /* webpackChunkName: "component---src-pages-free-security-evaluation-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-and-pricing-js": () => import("/opt/build/repo/src/pages/services-and-pricing.js" /* webpackChunkName: "component---src-pages-services-and-pricing-js" */)
}

